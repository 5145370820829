import React, {SFC, ReactNode, createRef } from 'react';
import IndexLayout from '../layouts';
import { Grid, Typography, Button, Container, Box } from '@mui/material';

import { graphql } from 'gatsby';

import patternImg from '../components/Blog/BlogHero/pattern.svg';
import { BlogPost } from '../components/Blog/BlogPost';
import BlogSocialShare from '../components/Blog/BlogSocialShare/BlogSocialShare';
import Footer from '../components/Footer';
import ReadingProgress from '../components/ReadingProgress/ReadingProgress';
import SEO from '../components/SEO';
import '../styles/_markdown.scss'


interface BlogTemplateProps {
  data: {
    contentfulBlogPost: {
      title: string;
      slug: string;
      buttonCta: boolean;
      body: {
        childMarkdownRemark: {
          html: string;
          excerpt: string;
          timeToRead: number;
        }
      }
      date: string;
      isoDate: string;
      author?: AuthorProps
    };
    nextBlogPosts: {
      nodes: BlogPost[]
    }
  };
  pathContext: {
    slug: string;
    locale: string;
  };
  location: Location;
}

interface AuthorProps {
  name: string;
  bio: string;
  profilePic: {
    resize: {
      src: string;
    };
  };
  className?: string;
}

const Author: SFC<AuthorProps> = ({name, bio, profilePic, className}) => (
  <Grid className={`${className} author`}>
    <img src={profilePic.resize.src} />
    <div>
      <Typography variant='caption' color="text.white" fontSize='20px'>{name}</Typography>
      <Typography variant='caption' color="text.white" fontSize='20px'>{bio}</Typography>
    </div>
  </Grid>
)

const BlogTemplate: SFC<BlogTemplateProps> = ({ data, pathContext, location }) => {
    const { contentfulBlogPost } = data;
    const html = contentfulBlogPost.body.childMarkdownRemark.html
    const author = contentfulBlogPost.author;
    const target = createRef<HTMLElement>();

    const newsletterSubscriber = typeof window !== 'undefined' && window.localStorage.getItem('newsletterSubscriber');

    const content = (
      <>
        <SEO
          title={`${contentfulBlogPost.title}`}
          description={contentfulBlogPost.body.childMarkdownRemark.excerpt}
        />
        <ReadingProgress target={target} />
        <Grid sx={{p:{md:'2rem', xs:'3.2rem 1rem'}}} style={{ backgroundImage: `url(${patternImg})`, backgroundColor: '#023365' }}>
          <Grid container >
            <Grid lg={12} md={12} sm={12} xs={12} sx={{display: 'flex', direction: 'row', justifyContent: 'center', alignItems:'center'}}>
              <Box sx={{px:{md:'10rem', sm:'none', xs:'none'}}}>
                <Typography fontSize='40px' variant='h1' color="text.white" sx={{my: '1.5rem'}}>
                  {contentfulBlogPost.title}
                </Typography>
                  {author ?
                    <Author name={author.name} bio={author.bio} profilePic={author.profilePic} className="mb-2" /> :
                    ''
                  }
                  <Typography variant='caption' color="text.white" fontSize='20px'>
                    {contentfulBlogPost.date}
                  </Typography>

                  <p className="mb-0 d-flex align-items-center">
                    
                      <Typography variant='body2' color="text.white" fontSize='20px'>
                        { `Lectura de ${contentfulBlogPost.body.childMarkdownRemark.timeToRead} minutos` }
                      </Typography>
                  </p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container md={12} sm={12}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid>
            <Grid sx={{ display:'flex', direction:'row', justifyContent:'center', alignItems:'center', my:'2rem' }}>
              <Box sx={{ zIndex: 1, display:'flex', direction:'row', justifyContent:'center', alignItems:'center', width:{ md: '60%', xs:'300px'} }}>
                <img src={contentfulBlogPost.mainImage.url} style={{ width:'100%' }}/>
              </Box>
            </Grid>
            <Grid sx={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', my:'2rem', px:'1rem' }}>
              <Grid>
                <BlogSocialShare title={contentfulBlogPost.title} url={location.pathname} />
              </Grid>
              <Grid lg={12} md={12} sm={12} xs={12} my={4} >
                <Box
                  sx={{px:{md:'25rem', xs:'none'}}}
                  className="markdown-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <BlogLatest posts={data.nextBlogPosts.nodes}/> */}
        <div className="ingenio-bg-footer">
          <Footer />
        </div>
      </>
    )

  return (
    <IndexLayout
      location={location}
      content= {content}
    />
  );
};

export default BlogTemplate;

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: {eq: $slug}) {
      title
      buttonCta
      date(formatString: "MMMM DD, YYYY")
      isoDate: date
      body {
        childMarkdownRemark {
          html
          excerpt
          timeToRead
        }
      }
      mainImage {
        url
      }
      author {
        name
        bio
        profilePic {
          resize(width: 60, height: 60, quality: 75) {
            src
          }
        }
      }
    }
    nextBlogPosts: allContentfulBlogPost(
      sort: {order: DESC, fields: date}
      filter: {
        node_locale: {eq: "es"},
        slug: {ne: $slug}
      },
      limit: 4
    ) {
      nodes {
        title
        slug
        body {
          childMarkdownRemark {
            excerpt
          }
        }
        date(formatString: "MMMM DD, YYYY", locale: "es")
        author {
          bio
          name
          profilePic {
            resize(width: 60, height: 60, quality: 75) {
              src
            }
          }
        }
        mainImage {
          url
        }
      }
    }
  }
`;
